import 'core-js/library/fn/set';
import 'core-js/library/fn/string';
import find from 'core-js/library/fn/array/virtual/find';
import includes from 'core-js/library/fn/array/virtual/includes';
import assign from 'core-js/library/fn/object/assign';
import entries from 'core-js/library/fn/object/entries';
import keys from 'core-js/library/fn/object/keys';
import values from 'core-js/library/fn/object/values';

/*
This fixes a "cannot assign readonly property '__esModule' to #<Object>" error that pops up on legacy browsers

The cause is mixing of imports and module.exports statements, since one is commonjs and the other is es6 modules.
Two potential causes:
 - Setting the modules setting of env to commonjs in babel
 - the use of babel root import which might be inserting import statements

Removing the env value in babelrc causes an invariant error somewhere, and we need babel-root-import for imports
This polyfill will bandage the issue until we can find the root cause of the mixup and remove it.
*/

const defineProperty = Object.defineProperty;
Object.defineProperty = function newDefProperty(exports, name) {
  if (name === '__esModule') {
    exports[name] = true; // eslint-disable-line no-param-reassign
    return undefined;
  }
  return defineProperty.apply(this, arguments); // eslint-disable-line prefer-rest-params
};

Array.prototype.find = find; // eslint-disable-line no-extend-native
Array.prototype.includes = includes; // eslint-disable-line no-extend-native
Object.assign = assign;
Object.entries = entries;
Object.keys = keys;
Object.values = values;
